
import * as storyblokRuntime$vovCYYBnLx from '/Users/chris/Projects/autoz/dealerme/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as cloudflareRuntime$idOHlVsdaP from '/Users/chris/Projects/autoz/dealerme/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as autoznetworkRuntime$BJFwkC09dX from '/Users/chris/Projects/autoz/dealerme/providers/autoznetwork.ts'
import * as noneRuntime$t9vHRzVS0B from '/Users/chris/Projects/autoz/dealerme/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$vovCYYBnLx, defaults: {"baseURL":"https://a-us.storyblok.com"} },
  ['cloudflare']: { provider: cloudflareRuntime$idOHlVsdaP, defaults: {"baseURL":"/dealer-images/"} },
  ['autoznetwork']: { provider: autoznetworkRuntime$BJFwkC09dX, defaults: {} },
  ['none']: { provider: noneRuntime$t9vHRzVS0B, defaults: {} }
}
        